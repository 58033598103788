<template>
    <div class="layout-px-spacing">
        

        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel br-6">
                    <div class="custom-table panel-body p-0 m-3">
                        <center>
                        <div class="panel-heading mb-0 ">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    
                                    <h3><span style="color:green;font-weight:900">Smile</span> Pricing Portal</h3>
                                   
                                </div>
                            </div>
                        </div>
                        


                        <card class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5" v-if="store.state.cans.includes('USER') ||store.state.cans.includes('ADM') || store.state.cans.includes('UC_DEV')">
                            <center>
                                <a href="javascript:;" class="dropdown-toggle autodroprown">
                                    <div class="" style="color:green; font-weight:900">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-layout"
                                        >
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                            <line x1="9" y1="21" x2="9" y2="9"></line>
                                        </svg><br/>
                                        <span class="m-lg-2">{{ $t('Unitcredits') }}</span>


                                    </div>
                                    
                                </a>
                                <div class="dropdown-content">
                                            <router-link to="/unitcredits">
                                                {{ $t('Show All') }}
                                            </router-link>
                                        
                                            <router-link to="/unitcredit/create" v-if="store.state.cans.includes('UC_DEV') ||store.state.cans.includes('ADM')">
                                                {{ $t('Create Unitcredit') }}
                                            </router-link>
                                        
                                            <router-link to="/unitcredit/edit" v-if="store.state.cans.includes('UC_DEV') ||store.state.cans.includes('ADM')">
                                                {{ $t('Edit Unitcredit') }}
                                            </router-link>
                                        
                                            <router-link to="/unitcredit/clone" v-if="store.state.cans.includes('UC_DEV') ||store.state.cans.includes('ADM')">
                                                {{ $t('Create from Another') }}
                                            </router-link>
                                        
                                            <router-link to="/unitcredit/maptoservice" v-if="store.state.cans.includes('UC_DEV') ||store.state.cans.includes('ADM')">
                                                {{ $t('Service Mapping') }}
                                            </router-link>
                                    
                                            <router-link to="/unitcredit/mappings" >
                                                {{ $t('View Svc Mappings') }}
                                            </router-link>
                                    
                                </div>
                            </center>
                        </card>

                    <!-- products -->
                        <card class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5"   v-if="store.state.cans.includes('USER') ||store.state.cans.includes('ADM') ||store.state.cans.includes('PRD_DEV')">
                            <center>
                                <a href="javascript:;" class="dropdown-toggle autodroprown">
                                    <div class="" style="color:green; font-weight:900">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-box"
                                        >
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg><br/> 
                                        <span>{{ $t('Products') }}</span>
                                    </div>
                                    
                                </a>
                                <div class="dropdown-content">
                                    
                                        <router-link to="/products">
                                            {{ $t('Show All') }}
                                        </router-link>
                                   
                                        <router-link to="/product/create"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('PRD_DEV')">
                                            {{ $t('Create Product') }}
                                        </router-link>
                                   
                                        <router-link to="/product/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('PRD_DEV')">
                                            {{ $t('Edit Product') }}
                                        </router-link>
                                  
                                        <router-link to="/product/maptoservice"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('PRD_DEV')">
                                            {{ $t('Service Mapping') }}
                                        </router-link>
                                   
                                        <router-link to="/product/mappings">
                                            {{ $t('View Svc Mappings') }}
                                        </router-link>
                                        <router-link to="/service/avps">
                                            {{ $t('View Product AVPs') }}
                                        </router-link>
                                   
                                        <router-link to="/service/avps/create" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                            {{ $t('Create Product AVP') }}
                                        </router-link>
                                   
                                        <router-link to="/service/avps/edit" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                            {{ $t('Edit Product AVP') }}
                                        </router-link>
                                    
                                    
                                </div>
                            </center>
                        </card>

                    <!-- services -->
                    <card class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5" v-if="store.state.cans.includes('USER') ||store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                        <center>
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                <div class="" style="color:green; font-weight:900">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-cpu"
                                    >
                                        <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                                        <rect x="9" y="9" width="6" height="6"></rect>
                                        <line x1="9" y1="1" x2="9" y2="4"></line>
                                        <line x1="15" y1="1" x2="15" y2="4"></line>
                                        <line x1="9" y1="20" x2="9" y2="23"></line>
                                        <line x1="15" y1="20" x2="15" y2="23"></line>
                                        <line x1="20" y1="9" x2="23" y2="9"></line>
                                        <line x1="20" y1="14" x2="23" y2="14"></line>
                                        <line x1="1" y1="9" x2="4" y2="9"></line>
                                        <line x1="1" y1="14" x2="4" y2="14"></line>
                                    </svg><br/>
                                    <span>{{ $t('Services') }}</span>
                                </div>
                                
                            </a>
                            <div class="dropdown-content">
                                
                                    <router-link to="/services">
                                        {{ $t('Show All') }}
                                    </router-link>
                               
                                    <router-link to="/service/create" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                        {{ $t('Create Service') }}
                                    </router-link>
                               
                                    <router-link to="/service/edit" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                        {{ $t('Edit Service') }}
                                    </router-link>

                                    <router-link to="/service/avps">
                                        {{ $t('View AVPs') }}
                                    </router-link>
                               
                                    <router-link to="/service/avps/create" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                        {{ $t('Create Service AVP') }}
                                    </router-link>
                               
                                    <router-link to="/service/avps/edit" v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('SVC_DEV')">
                                        {{ $t('Edit Service AVP') }}
                                    </router-link>
                                
                            </div>
                        </center>
                    </card>



                     <!-- Campaigns -->
                     <card class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5"  v-if="store.state.cans.includes('USER') ||store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                        <center>
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                <div class="" style="color:green; font-weight:900">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-clipboard"
                                >
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                </svg><br/>
                                    <span>{{ $t('Campaigns') }}</span>
                                </div>
                                
                            </a>
                            <div class="dropdown-content">
                                
                                
                                    <router-link to="/campaigns">
                                        {{ $t('Show All') }}
                                    </router-link>
                             
                                
                                    <router-link to="/campaign/create"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Create Campaign') }}
                                    </router-link>
                                
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Edit Campaign') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Create Enticement') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Edit Enticement') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('View All Enticements') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Create CampaignList') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Edit CampaignList') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('View All CampaignLists') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Create CampaignRun') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Edit CampaignRun') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('View CampaignRun') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Create CampaignTrigger') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('Edit CampaignTrigger') }}
                                    </router-link>
                                    <router-link to="/campaign/edit"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        {{ $t('View CampaignTrigger') }}
                                    </router-link>


                                    <router-link to=""  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('CMP_DEV')">
                                        Query Builder - <span style="color:red">Removed</span>
                                    </router-link>
                               
                                
                            </div>
                        </center>
                    </card>


                     <!-- Admin -->
                     <card class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5"  v-if="store.state.cans.includes('ADM')">
                        <center>
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                
                                    <div class="" style="color:green; font-weight:900">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-layout"
                                        >
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                           
                                            <line  y1="21" x2="9"></line>
                                        </svg><br/>
                                        <span>{{ $t('Admin') }}</span>
                                    </div>
                                
                            </a>
                            <div class="dropdown-content">
                                <router-link to="/users">View All Users</router-link>
                                <router-link to="/user/create">Create User</router-link>
                                <router-link to="/user/edit">Edit User</router-link>
                                <router-link to="/user">View User</router-link>
                                    
                                   
                                
                            </div>
                        </center>
                    </card>
                    
                    <!-- Configs -->

                    <!--
                    <card style="background:grey" class="dropdown shadow-lg p-3 mb-5 bg-body rounded m-5"  v-if="store.state.cans.includes('ADM') ||store.state.cans.includes('DEV')">
                        <center>
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                <div class="" style="color:green; font-weight:900">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-box"
                                >
                                    <path d="M21 1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0"></path>
                                    <polyline points="3.27 6.96  6.96"></polyline>
                                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                </svg><br/>
                                 Sys Configs
                                </div>
                                
                            </a>
                            <div class="dropdown-content">
                                <router-link to="" style="background-color: #bbb; color:white; font-weight:900">Properties <br/><span style="color:red">Removed</span></router-link>
                                <router-link to="">New Property</router-link>
                                <router-link to="">Edit Property</router-link>
                                <router-link to="">View Property</router-link>
                                <router-link to="">View All</router-link>

                                <router-link to="" style="background-color: #bbb; color:white; font-weight:900">Roles<br/> <span style="color:red">Removed</span></router-link>
                                <router-link to="">New Role</router-link>
                                <router-link to="">Edit Roles</router-link>
                                <router-link to="">View Roles</router-link>
                                <router-link to="">Assign to Actions</router-link>
                                <router-link to="" style="background-color: #bbb; color:white; font-weight:900">Auditing + JIRA<br/> <span style="color:red">Removed</span></router-link>
                                <router-link to="">View Trace Settings</router-link>
                                <router-link to="">New Trace Setting</router-link>
                                <router-link to="">Edit Trace Setting</router-link>
                                <router-link to="">Remove Trace Setting</router-link>
                               
                                
                            </div>
                        </center>
                    </card>
                    -->
                    </center>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const atHome = ref(false);
onMounted(async () => {
        
    atHome.value = true;
        

    });
    
</script>

<style>


.dropdown {
  position: relative;
  display: inline-block;
  width: 120px;
  border: 0px solid transparent;
  transition: .3s;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;  
  border-radius: 10px;
  width: 180px;
  transition: .3s;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
 
}

.dropdown-content:hover {background-color: whitesmoke; width: 250px;
   }

.dropdown-content a:hover {background-color: rgb(231, 247, 232); width: 250px;
}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover {background-color: whitesmoke; width: 150px; border: 2px solid green;}

</style>

