<template>
    <div>
        
        
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                           <h1 style="color:green;font-weight:900">SMILE</h1>
                            <!-- <img src="@/assets/images/logo.svg" class="navbar-logo" alt="logo" /> -->
                        </router-link>
                    </li>
                    <li class="nav-item theme-text">
                        <router-link to="/" class="nav-link"> SPP </router-link>
                    </li>
                </ul>
                
                <button @click="route.push('/')" class="btn bg-transparent">
                    
                    <mdicon style="color:white" name="menu" :spin="true"  width="564" height="464" />
                    <span style="color:white; font-weight:900; hover: color:green">MENU</span>
                    
                </button>    
            </header>

            
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    const route = useRouter();

    const store = useStore();

    const selectedLang = ref(null);

    const i18n = useI18n();

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };

    const signout = () => {
        store.commit('logout');
    };



</script>
