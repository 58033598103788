<template>
    <div class="footer-wrapper">
        <center>
            <div class="footer-section f-section-1">
                <p class="">Copyright © 2022 Smile Communications, All rights reserved.</p>
            </div>
        </center>

    </div>
</template>
