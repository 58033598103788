import { createStore } from 'vuex';
import i18n from '../i18n';
import { isJwtExpired } from 'jwt-check-expiration';
import { useRouter } from 'vue-router';
import jwt_decode from "jwt-decode";




export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        menu_style: 'vertical',
        layout_style: 'full',
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value='horizontal';
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value='full';
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },

        login (state,data) {   
            
           var pms = jwt_decode(data.tkn); 
           state.cans = pms.cans;   
           state.usr = data.usr;  
           state.tkn = data.tkn;
           state.sub = pms.sub;

           localStorage.setItem('usr', data.usr);
           localStorage.setItem('tkn', data.tkn);
           localStorage.setItem('cans', pms.cans);
           localStorage.setItem('sub', pms.sub);

           if(pms.sub.includes('PC')) {
            window.location.href = "/changepass";
           } else {
             window.location.href = "/";
           }

            
        },

        logout(state){
            state.tkn =null;
            state.cans = null;
            state.usr = null;  
            state.sub = null;

            localStorage.removeItem('tkn');
            localStorage.removeItem('cans');
            localStorage.removeItem('usr');    
            localStorage.removeItem('sub');    
            window.location.href = "/auth/login";

        },

         

        initialiseStore(state) {
        if(localStorage.getItem('tkn') && !isJwtExpired(localStorage.getItem('tkn'))){
            state.tkn = localStorage.getItem('tkn');
            state.cans = localStorage.getItem('cans');
            state.usr = localStorage.getItem('usr');
            state.sub = localStorage.getItem('sub');
        } else {
            
            localStorage.removeItem('tkn');
            localStorage.removeItem('cans'); 
            localStorage.removeItem('usr');    
            localStorage.removeItem('sub');    
        }
    }

        
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {},
    modules: {},
});
